import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import theme from "../styles/theme";

const ContentsMenu = ({ slug, data }) => {
  const [showDrops, setShowDrops] = useState([]);

  const menuArr = data.slice().reverse();

  const parents = menuArr
    .map((item, index) =>
      item.node.treeChildren.length > 0 ? index : undefined
    )
    .filter((item, index) => item === index);

  useEffect(() => {
    parents.forEach(item => {
      if (
        menuArr[item].node.slug === slug ||
        menuArr[item].node.treeChildren.some(item => item.slug === slug)
      ) {
        setShowDrops([...showDrops, item]);
      }
    });
  }, []);

  return (
    <nav
      className="navbar"
      css={`
        display: flex;
        flex-direction: column;
        min-width: 320px;
        .linkGroup {
          position: relative;
          &.alone a {
            padding-left: 0;
          }
          .linksWrapper {
            height: 0;
          }
          .linksContent {
            overflow: hidden;
          }
        }
        .linkGroup .links a,
        .alone a,
        a.active,
        a.groupTitle {
          font-size: 18px;
          display: flex;
          align-items: center;
          margin-top: 4px;
          margin-bottom: 4px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-right: 10px;
          color: ${theme.pink};
          font-weight: 900;
          justify-content: flex-start;
          &:hover {
            color: #474747;
          }
        }
        .linkGroup .links a {
          font-weight: 600;
          padding-top: 4px;
          padding-bottom: 4px;
          margin-top: 2px;
          margin-bottom: 2px;
        }
        a.groupTitle {
          display: inline-flex !important;
        }
        .linkGroup svg {
          margin-left: 0;
          transition: transform 0.4s ease-in-out;
        }
        .linkGroup svg.carretTurn {
          transform: rotate(180deg);
        }
        a.active {
          display: inline-flex;
          color: #474747 !important;
        }
        .dropBtn {
          padding: 10px;

          display: inline-flex;
          cursor: pointer;

          &:hover {
            svg path {
              transition: all 0.2s;
              fill: #474747;
            }
          }
        }
        .dropdown:hover .dropdown-content {
          display: flex;
        }
        .links {
          display: none;
        }
        .links.showDrop {
          display: block;
        }
      `}
    >
      {menuArr.map((item, index) => {
        if (item.node.treeChildren.length > 0) {
          return (
            <div class="linkGroup" key={item.node.slug}>
              <Link
                className="groupTitle"
                to={`/technical-description/${item.node.slug}`}
              >
                {item.node.title}
              </Link>
              <div
                className="dropBtn"
                role="button"
                tabIndex={0}
                onClick={() => {
                  showDrops.includes(index)
                    ? setShowDrops(showDrops.filter(i => i !== index))
                    : setShowDrops([...showDrops, index]);
                }}
              >
                <svg
                  className={showDrops.includes(index) && "carretTurn"}
                  width="13"
                  height="10"
                  viewBox="0 0 15 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.95402 11.0607L13.719 3.68185C14.5441 2.40399 13.556 0.77643 11.9549 0.77643L2.42507 0.776431C0.824038 0.776431 -0.164133 2.40399 0.661052 3.68185L5.42598 11.0607C6.22593 12.2995 8.15407 12.2995 8.95402 11.0607Z"
                    fill={theme.pink}
                  />
                </svg>
              </div>

              <div
                id={`menu-${index}`}
                className={`links ${showDrops.includes(index) && "showDrop"}`}
              >
                {item.node.treeChildren.map(item => {
                  return (
                    <Link
                      key={item.slug}
                      to={`/technical-description/${item.slug}`}
                    >
                      {item.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          );
        }
        if (item.node.treeChildren.length === 0) {
          return (
            <div key={item.node.slug} className="linkGroup alone">
              <Link to={`/technical-description/${item.node.slug}`}>
                {item.node.title}
              </Link>
            </div>
          );
        }

        return null;
      })}
    </nav>
  );
};

export default ContentsMenu;
