import { graphql } from "gatsby";
import * as React from "react";
import ContentsMenu from "../components/ContentsMenu";
import Layout from "../components/layout";
import theme from "../styles/theme";

const PosGuidePage = ({ location, data }) => {
  const seo = {
    tags: [
      {
        tagName: "title",
        content: "Technical Description",
      },
    ],
  };

  return (
    <Layout location={location} seo={seo}>
      <main
        css={`
          iframe {
            width: 100%;
            height: 100vh;
          }
        `}
      >
        <iframe
          src="https://ambiguous-llama-d9c.notion.site/Yonoton-PoS-System-User-Guide-for-Cashiers-bdd817128ae6437da039cc46c8c3465a"
          title="W3Schools Free Online Web Tutorials"
        ></iframe>
      </main>
    </Layout>
  );
};

export default PosGuidePage;
